import { Suspense } from 'react';
import { BrowserRouter } from '@leagueplatform/routing';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ErrorBoundary } from 'react-error-boundary';
import { useOnlineStatus } from '@leagueplatform/web-common';
import {
  FullPageError,
  LoadingIndicator,
} from '@leagueplatform/web-common-components';
import League from '@leagueplatform/core';
import { HeroBanner } from 'components/hero-banner/hero-banner.component';
import { MasonryWSDriverNodeRenderersRegistry } from '@leagueplatform/masonry';
import { ROUTE_PATHS } from 'common/constants/constants';
import { ExitWarningContextProvider } from 'components/modals/exit-warning/exit-warning.context';
import { GenericErrorPage } from 'components/error-page/error-page.component';
import { genericErrorProps } from 'common/constants/error-page';
import AppRoutes from './components/app-routes.component';
import { config, routesConfig } from './config';

MasonryWSDriverNodeRenderersRegistry.overrideRenderer(
  'default',
  'heroBanner',
  HeroBanner,
);

League.overwriteRoutePaths(routesConfig);

export function App() {
  return (
    <League.LeagueProvider config={config}>
      <ErrorBoundary fallback={<GenericErrorPage {...genericErrorProps} />}>
        <Suspense fallback={<LoadingIndicator />}>
          {useOnlineStatus().isOffline && (
            <FullPageError route={ROUTE_PATHS.HOME} />
          )}
          {useOnlineStatus().isOnline && (
            <ExitWarningContextProvider>
              <BrowserRouter>
                <AppRoutes />
              </BrowserRouter>
            </ExitWarningContextProvider>
          )}
          <ReactQueryDevtools />
        </Suspense>
      </ErrorBoundary>
    </League.LeagueProvider>
  );
}
