/* eslint-disable import/no-extraneous-dependencies */
import { Suspense } from 'react';
import { Route, Switch } from '@leagueplatform/routing';
import { getModulePath, LEAGUE_MODULE_NAMES } from '@leagueplatform/core';
import { ROUTE_PATHS } from 'common/constants/constants';
import { Box } from '@leagueplatform/genesis-core';
import {
  LandingPage,
  CareCollectionsRoutes,
  CareDiscoveryRoutes,
  AboutMeRoutes,
  HealthProfileRoutes,
  Home,
  WalletRoutes,
  AccountInfo,
  ChangeDoctor,
  HealthJourneyRoutes,
  MessagingRoutes,
  CareDocumentsRoutes,
} from 'components/lazy-routes.component';
import { AuthViewLogin, AuthViewLogout } from 'pages/auth/auth.pages';
import { PBPChange } from 'pages/pbp-change/pbp-change.page';
import { AuthorizedRoutes } from 'components/authorized-routes/authorized-routes.component';
import { PrivateRoutesContainer } from 'components/private-routes-container/private-routes-container';
import { GenericSkeletonPage } from 'components/skeleton-page/skeleton-page.component';
import { CommunicationPreferencesPage } from 'pages/communication-preferences/communication-preferences.page';
import { DocumentPreferencesPage } from 'pages/document-preferences/document-preferences.page';
import { AboutPage } from 'pages/about/about.page';
import { pageNotFoundProps } from 'common/constants/error-page';
import { GenericErrorPage } from 'components/error-page/error-page.component';
import { BasePathRedirect } from 'components/base-path-redirect/base-path-redirect.component';

// CareDiscovery overflows our grid layout parent at the bottom in mobile width.
// It's made of StackLayout, which has height: 100%.
// It's built in Masonry 1.0, so we can't override Masonry's StackLayout (we could in 1.5).
// It doesn't have any distinct class names to target the div directly.
// The grandchildren of this Box are the 2 container columns.
// Adding more elements inside the columns will NOT disrupt the grandchildren selector.
const StyledCareDiscoveryRoutes = () => (
  <Box
    css={{
      '& > div > div': { height: 'max-content' },
    }}
  >
    <CareDiscoveryRoutes />
  </Box>
);

function AppRoutes() {
  return (
    <Switch>
      <Route path={ROUTE_PATHS.SIGN_IN} component={AuthViewLogin} />
      <Route path={ROUTE_PATHS.SIGN_OUT} component={AuthViewLogout} />
      <Route path="/" exact component={LandingPage} />
      <AuthorizedRoutes>
        <Switch>
          {/* Authorized view without top nav and global footer */}
          <Route
            path={ROUTE_PATHS.CHANGE_DOCTOR}
            render={() => (
              <Suspense fallback={<GenericSkeletonPage />}>
                <ChangeDoctor />
              </Suspense>
            )}
            exact
          />
          {/* Authorized views with top nav and global footer */}
          <PrivateRoutesContainer>
            <Switch>
              {/* Remove all /member paths - they're hardcoded in some SDKs but the app doesn't handle them anymore. */}
              <Route path={ROUTE_PATHS.MEMBER_REDIRECT}>
                <BasePathRedirect targetBasePath="" />
              </Route>
              <Route
                path={ROUTE_PATHS.HOME}
                component={Home}
                exact
                key="home"
              />
              <Route
                path={ROUTE_PATHS.ACCOUNT_INFO}
                render={() => (
                  <Suspense fallback={<GenericSkeletonPage />}>
                    <AccountInfo />
                  </Suspense>
                )}
                exact
              />
              <Route
                path={ROUTE_PATHS.COMMUNICATION_PREFERENCES}
                component={CommunicationPreferencesPage}
                exact
              />
              <Route path={ROUTE_PATHS.ABOUT} component={AboutPage} exact />
              <Route
                path={ROUTE_PATHS.DOCUMENT_PREFERENCES()}
                render={() => (
                  <Suspense fallback={<GenericSkeletonPage />}>
                    <DocumentPreferencesPage />
                  </Suspense>
                )}
                exact
              />
              <Route
                path={getModulePath(LEAGUE_MODULE_NAMES.careCollections)}
                component={CareCollectionsRoutes}
              />
              <Route
                path={getModulePath(LEAGUE_MODULE_NAMES.careDiscovery)}
                component={StyledCareDiscoveryRoutes}
              />
              <Route
                path={getModulePath(LEAGUE_MODULE_NAMES.wallet)}
                component={WalletRoutes}
              />
              <Route
                path={getModulePath(LEAGUE_MODULE_NAMES.aboutMe)}
                component={AboutMeRoutes}
              />
              {/* About Me in Masonry v.1 has metrics hardcoded to /health-profile.
              The metrics are now under /about-me. */}
              <Route
                path={`${getModulePath(
                  LEAGUE_MODULE_NAMES.healthProfile,
                )}/insights`}
              >
                <BasePathRedirect
                  targetBasePath={getModulePath(LEAGUE_MODULE_NAMES.aboutMe)}
                />
              </Route>
              <Route
                path={getModulePath(LEAGUE_MODULE_NAMES.healthProfile)}
                component={HealthProfileRoutes}
              />
              <Route
                path={getModulePath(LEAGUE_MODULE_NAMES.messaging)}
                component={MessagingRoutes}
              />
              <Route
                path={getModulePath(LEAGUE_MODULE_NAMES.careDocuments)}
                component={CareDocumentsRoutes}
              />
              <Route
                path={[
                  getModulePath(LEAGUE_MODULE_NAMES.healthJourney),
                  getModulePath(LEAGUE_MODULE_NAMES.healthPrograms),
                  getModulePath(LEAGUE_MODULE_NAMES.healthProgress),
                  getModulePath(LEAGUE_MODULE_NAMES.healthJourneyHistory),
                ]}
                component={HealthJourneyRoutes}
              />
              <Route path="/pbp-change" component={PBPChange} />
              <Route
                render={() => <GenericErrorPage {...pageNotFoundProps} />}
              />
            </Switch>
          </PrivateRoutesContainer>
        </Switch>
      </AuthorizedRoutes>
    </Switch>
  );
}

export default AppRoutes;
