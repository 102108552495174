import type { AnchorHTMLAttributes, ReactNode } from 'react';

interface ExternalLinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  children: ReactNode;
}

export const ExternalLink = ({ children, ...props }: ExternalLinkProps) => (
  <a {...props} target="_blank" rel="noopener noreferrer">
    {children}
  </a>
);
