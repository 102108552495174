import { useState } from 'react';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';
import { useGetScanUserProfile } from 'hooks/use-get-scan-user-profile/use-get-scan-user-profile.hook';
import { useUploadFile } from '../use-upload-file/use-upload-file.hook';
import { useUpdateUserProfile } from '../use-set-user-profile/use-set-user-profile.hook';

const fileSizeLimit = 1000000; // 1MB

export const useUserProfilePhoto = () => {
  // Uploaded photo
  const [uploadedPhotoId, setUploadedPhotoId] = useState('');

  // Display currently uploaded or existing photo
  const { data: { userProfile } = {} } = useGetScanUserProfile();
  const profilePhotoId = uploadedPhotoId || userProfile?.avatar?.imageId;

  // Update user profile with new photo
  const { updateUserProfile } = useUpdateUserProfile({
    onSuccess: () => {
      toastActions.add({
        type: TOAST_STATUS.SUCCESS,
        textId: 'STR_PROFILE_PHOTO_UPDATED',
      });
    },
    onError: () => {
      toastActions.add({
        type: TOAST_STATUS.ERROR,
        textId: 'STR_PROFILE_PHOTO_UPDATE_FAILED',
      });
      setUploadedPhotoId('');
    },
  });

  // Photo upload
  const uploadFile = useUploadFile();
  const uploadProfilePhoto = async (file: File) => {
    if (file.size > fileSizeLimit) {
      toastActions.add({
        type: TOAST_STATUS.ERROR,
        textId: 'STR_FILE_TOO_LARGE_ERROR',
      });
      return;
    }

    const newFileId = await uploadFile(file);
    if (!newFileId) {
      toastActions.add({
        type: TOAST_STATUS.ERROR,
        textId: 'STR_FILE_UPLOAD_ERROR',
      });
      return;
    }

    setUploadedPhotoId(newFileId);
    updateUserProfile({
      avatar: { imageId: newFileId },
    });
  };

  return {
    profilePhotoId,
    uploadProfilePhoto,
  };
};
