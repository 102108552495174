import { useGetScanUserProfile } from 'hooks/use-get-scan-user-profile/use-get-scan-user-profile.hook';

export const useUserName = () => {
  const { data: { userProfile } = {} } = useGetScanUserProfile();

  const userFirstName = userProfile?.firstName ?? '';

  const userFullName = `${userProfile?.firstName ?? ''} ${
    userProfile?.lastName ?? ''
  }`;

  const userInitials = `${userFirstName?.[0] ?? ''}${
    userProfile?.lastName?.[0] ?? ''
  }`;

  return {
    userFirstName,
    userFullName,
    userInitials,
  };
};
