import { useIntl } from '@leagueplatform/locales';
import { DATE_FORMAT } from '@leagueplatform/web-common';

const getFutureDate = (date: Date, monthsAhead: number) => {
  const result = new Date(date);
  result.setMonth(result.getMonth() + monthsAhead);
  result.setDate(1);
  return result;
};

export const useMonthsAhead = (months = 2) => {
  const { formatDate } = useIntl();
  const datesArray = [];
  const getDateValues = (newDate: Date) => ({
    value: newDate.toISOString(),
    label: formatDate(newDate, DATE_FORMAT),
  });

  // Generate first of the month in the future based on current date
  const now = new Date();
  for (let i = 0; i < months; i += 1) {
    datesArray.push(getDateValues(getFutureDate(now, i + 1)));
  }
  return datesArray;
};
