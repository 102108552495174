import { useState } from 'react';
import { LOCAL_STORAGE_KEYS } from 'common/constants/constants';
import { useGetScanUserProfile } from 'hooks/use-get-scan-user-profile/use-get-scan-user-profile.hook';

export const useOnboarding = () => {
  const { data: userProfileData, isLoading: userProfileDataIsLoading } =
    useGetScanUserProfile();

  // TODO [SCAN-765] update onboarding field on user profile
  const userCompletedAccountSetup =
    !!userProfileData?.userProfile?.memberTermsAndConditions?.dateAccepted;
  const [featureHighlightsCompleted, setFeatureHighlightsCompleted] =
    useState<boolean>(
      !!window.localStorage.getItem(
        LOCAL_STORAGE_KEYS.FEATURE_HIGHLIGHTS_COMPLETED,
      ),
    );

  const [showAccountSetupCompleted, setShowAccountSetupCompleted] =
    useState<boolean>(false);

  const onFeatureHighlightsComplete = () => {
    setFeatureHighlightsCompleted(true);
    window.localStorage.setItem(
      LOCAL_STORAGE_KEYS.FEATURE_HIGHLIGHTS_COMPLETED,
      'true',
    );
  };

  const onCompleteAccountSetup = () => {
    setShowAccountSetupCompleted(true);
    // TODO [SCAN-765] call mutation to update onboarding field in the background
  };
  const onCompleteOnboarding = () => setShowAccountSetupCompleted(false);

  return {
    showAccountSetup: featureHighlightsCompleted && !userCompletedAccountSetup,
    showFeatureHighlights:
      !userCompletedAccountSetup && !featureHighlightsCompleted,
    onFeatureHighlightsComplete,
    isLoading: userProfileDataIsLoading,
    showAccountSetupCompleted,
    onCompleteAccountSetup,
    onCompleteOnboarding,
  };
};
