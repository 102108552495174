import { styled, type GDSStyleObject } from '@leagueplatform/genesis-core';
import LOGO from 'assets/images/scan-logo.png';
import { useIntl } from '@leagueplatform/locales';

type LogoProps = {
  width: GDSStyleObject['width'];
  css?: GDSStyleObject;
};

const StyledImage = styled('img');

export const Logo = ({ width, css }: LogoProps) => {
  const { formatMessage } = useIntl();
  return (
    <StyledImage
      role="presentation"
      alt={formatMessage({ id: 'STR_COMPANY_NAME' })}
      src={LOGO}
      css={{
        width,
        ...css,
      }}
    />
  );
};
