import { lazy } from 'react';

export const LandingPage = lazy(() => import('pages/landing.page'));

export const Home = lazy(() => import('pages/home/home.page'));

export const AccountInfo = lazy(
  () => import('pages/account-info/account-info.page'),
);

export const ChangeDoctor = lazy(
  () => import('pages/change-doctor/change-doctor.page'),
);

export const CareCollectionsRoutes = lazy(() =>
  import('@leagueplatform/care-collections').then((module) => ({
    default: module.CareCollectionsRoutes,
  })),
);

export const CareDiscoveryRoutes = lazy(() =>
  import('@leagueplatform/care-discovery').then((module) => ({
    default: module.CareDiscoveryRoutes,
  })),
);

export const CareDocumentsRoutes = lazy(() =>
  import('@leagueplatform/care-documents').then((module) => ({
    default: module.CareDocumentsRoutes,
  })),
);

export const WalletRoutes = lazy(() =>
  import('@leagueplatform/wallet').then((module) => ({
    default: module.WalletRoutes,
  })),
);

export const AboutMeRoutes = lazy(() =>
  import('@leagueplatform/about-me').then((module) => ({
    default: module.AboutMeRoutes,
  })),
);

export const HealthProfileRoutes = lazy(() =>
  import('@leagueplatform/health-profile').then((module) => ({
    default: module.HealthProfileRoutes,
  })),
);

export const MessagingRoutes = lazy(() =>
  import('@leagueplatform/messaging').then((module) => ({
    default: module.MessagingRoutes,
  })),
);

export const HealthJourneyRoutes = lazy(() =>
  import('@leagueplatform/health-journey').then((module) => ({
    default: module.HealthJourneyRoutes,
  })),
);

export const OnboardingRoutesWithProvider = lazy(() =>
  import('@leagueplatform/onboarding').then((module) => ({
    default: module.OnboardingRoutesWithProvider,
  })),
);
