import { GenericErrorPageProps } from 'components/error-page/error-page.component';

export const genericErrorProps: GenericErrorPageProps = {
  imageSrc: 'src/assets/generic-error-illustration.png',
  headingLocaleKey: 'STR_SOMETHING_WENT_WRONG',
  bodyLocaleKey: 'STR_SERVER_CONNECTION_ERROR',
  buttonLocaleKey: 'STR_RELOAD',
  isFullPage: true,
};

export const pageNotFoundProps: GenericErrorPageProps = {
  imageSrc: 'src/assets/page-not-found.png',
  headingLocaleKey: 'STR_PAGE_NOT_FOUND',
  bodyLocaleKey: 'STR_SORRY_WE_CANT_FIND_PAGE',
  buttonLocaleKey: 'STR_GO_TO_HOME',
  hasGoToHomepage: true,
};
