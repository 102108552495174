export const formatSnakeToCamel = (word: string) => {
  const result = [];
  for (let i = 0; i < word.length; i += 1) {
    if (word[i] === '_') {
      result.push(word[i + 1].toUpperCase());
      i += 1;
    } else result.push(word[i]);
  }
  return result.join('');
};
