import {
  Box,
  HeadingText,
  StackLayout,
  styled,
} from '@leagueplatform/genesis-core';
import { CONTENT_MAX_WIDTH, HERO_BANNER_HEIGHT } from 'common/constants/ui';
import { useIsBelowTablet } from 'hooks/use-is-below-tablet/use-is-below-tablet.hooks';

type HeroBannerProps = {
  heading: string;
  image: string;
};

const Image = styled('img', {
  height: 151,
});

export const HeroBanner = ({ heading, image }: HeroBannerProps) => {
  const isBelowTablet = useIsBelowTablet();
  return (
    <Box
      className="custom-header"
      css={{
        position: 'absolute',
        width: '100%',
        left: 0,
        top: 0,
        backgroundColor: '$surfaceBackgroundTertiary',
        overflow: 'hidden',
      }}
    >
      <StackLayout
        horizontalAlignment="spaceBetween"
        verticalAlignment="center"
        orientation="horizontal"
        css={{
          // Max width + padding
          maxWidth: `calc(${CONTENT_MAX_WIDTH}px + $oneAndHalf + $oneAndHalf)`,
          paddingInlineStart: '$oneAndHalf',
          paddingInlineEnd: isBelowTablet ? '$one' : '$oneAndHalf',
          marginInline: 'auto',
          height: HERO_BANNER_HEIGHT,
        }}
      >
        <HeadingText
          size={isBelowTablet ? 'xl' : 'xxl'}
          level="1"
          {...(isBelowTablet && {
            css: { alignSelf: 'flex-start', marginBlockStart: '$twoAndHalf' },
          })}
        >
          {heading}
        </HeadingText>
        <Image
          alt=""
          src={image}
          css={{ marginBlockEnd: isBelowTablet ? -54 : -40 }}
        />
      </StackLayout>
    </Box>
  );
};
